import React from 'react'
import './about.css'

const About = () => {
  return (
    <div class="post-wrap">
      <div class="post-item">
        <div class="item-content">
          <div class="item-icon tree"></div>
          <div class="item-body">
            <h3>С кем и откуда?</h3>
            <p>Мы работаем напрямую с заводами-производителями: ПАО «Сибур Холдинг», ПАО «ХИМПРОМ», ПАО «КАЗАНЬОРГСИНТЕЗ», АО «Аммоний», «AZERKIMYA», «Cloran Chemical PC» и др.
              Несмотря на то, что мы молодая компания, мы стремительно наращиваем географию наших поставок.
              Мы готовы импортировать из ИРАНА,ТУРЦИИ и КИТАЯ химическую продукцию по вашим заявкам в
              самые короткие сроки.</p>
          </div>
        </div>
      </div>

      <div class="post-item">
        <div class="item-content">
          <div class="item-icon tree"></div>
          <div class="item-body">
            <h3>Стратегия</h3>
            <p>1. Поставка качественного химического сырья, в т.ч. импортного, производителям химической
               продукции России на постоянной основе, оптимизируя логистические цепи поставок.</p>
            <p>2. Расширение географии экспортных поставок химической продукции российских
               производителей на зарубежные рынки.</p>
          </div>
        </div>
      </div>

      <div class="post-item">
        <div class="item-content">
          <div class="item-icon tree"></div>
          <div class="item-body">
            <h3>Кому?</h3>
            <p>Компания ООО «РУСЬКИМЯ» сейчас поставляет сырьё для разных сфер производства:</p>
            <p>-Производства строительных материалов</p>
            <p>-Химическая промышленность</p>
            <p>-Бытовая химия</p>
            <p>-Металлургия</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About

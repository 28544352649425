import React from 'react'
import { Row } from 'react-bootstrap'
import ProductItem from './ProductItem'

import causticsoda_granul from '../Assets/Каустическая_сода_гранул.jpg'
import sulfkis from '../Assets/Сульфаминовая_кислота.png'
import monoetilen from '../Assets/этилен.png'
import causticsoda from '../Assets/сода_каустическая.png'
import dietanolamid from '../Assets/диэтаноламид.jpg'
import benzat from '../Assets/Бензоат.jpg'
import tripolifosfat from '../Assets/Триполифосфат натрия.png'
import propilenglikol from '../Assets/Пропиленгликоль1.jpg'
import propilenglikol_kan from '../Assets/Пропиленгликоль_канистра.jpg'

const ProductList = () => {
    const Products = [
        {
          id: 1,
          img: causticsoda,
          name: 'Каустическая сода, чешуированная',
          desc: `Применяется для бытовых нужд, а также в разных отраслях промышленности. 
          В быту используется для растворения жира, волос, остатков пищи, отстирывания пятен мазута, дезинфекции.`,
          manufacturer: "Иран, CHLORAN",
          packing: "Мешки 25кг (новые)",
          price: 'от 79 руб/кг (с НДС)',
        },
        {
          id: 2,
          img: causticsoda_granul,
          name: 'Каустическая сода, гранулированная',
          desc: `Применяется для бытовых нужд, а также в разных отраслях промышленности. 
          В быту используется для растворения жира, волос, остатков пищи, отстирывания пятен мазута, дезинфекции.`,
          manufacturer: "АО «Каустик», Волгоград",
          packing: "Мешки 25кг (новые)",
          price: 'от 84 руб/кг (с НДС)',
        },
        {
          id: 3,
          img: benzat,
          name: 'Бензоат натрия',
          desc: `Часто применимый консервант, обеспечивающий свежесть продуктов. 
          Угнетает разввитие дрожжевых клеток, грибов, некоторых видов бактерий. 
          Натрий бензойнокислый является водорастворимым веществом.`,
          manufacturer: "Китай",
          packing: "Мешки 25кг",
          price: 'от 220 руб/кг (с НДС)',
        },
        {
          id: 4,
          img: sulfkis,
          name: 'Сульфаминовая кислота',
          desc: 'Применяется для промывки внутренних поверхностей теплотехнического оборудования на ТЭЦ; в качестве компонента в производстве чистящих средств и в других отраслях народного хозяйства.',
          manufacturer: "АО «ПИГМЕНТ»",
          packing: "Мешки 40кг",
          price: 'от 177 руб/кг (с НДС)',
        },
        {
          id: 5,
          img: dietanolamid,
          name: 'Диэтаноламид (кокодиэтаноламид кокосового масла)',
          desc: `Применяется в производстве средств личной гигиены, 
          производстве бытовых моющих средств, в производстве промышленных моющих средств и др.`,
          manufacturer: "Малайзия",
          packing: "Бочки 200кг",
          price: 'от 220 руб/кг (с НДС)',
        },
        {
          id: 6,
          img: tripolifosfat,
          name: 'Триполифосфат натрия ,технический',
          desc: `Основной компонент большинства стиральных порошков.
          Это вещество способно разлагать жиры, превращая их в эмульсию.
          Кроме того, триполифосфат Na применяется для дезинфекции.`,
          manufacturer: "АО «Апатит»",
          packing: "Мешки 50кг",
          price: 'от 120 руб/кг (с НДС)',
        },
        {
          id: 7,
          img: propilenglikol,
          name: 'Пропиленгликоль технический и пищевой',
          desc: `Имеет широкое применение в производстве средств личной гигиены, в пищевой промышленности,
          в медицине и косметологии, производстве смесей для электронных сигарет, в химической промышленности и др.`,
          manufacturer: "Китай",
          packing: "Бочки 215кг",
          price: 'от 172 руб/кг (с НДС)',
        },
        {
          id: 8,
          img: propilenglikol_kan,
          name: 'Пропиленгликоль технический и пищевой',
          desc: `Имеет широкое применение в производстве средств личной гигиены, в пищевой промышленности,
          в медицине и косметологии, производстве смесей для электронных сигарет, в химической промышленности и др.`,
          manufacturer: "Китай",
          packing: "Канистра 10кг",
          price: 'от 1900 руб/шт',
        },
        {
          id: 9,
          img: propilenglikol_kan,
          name: 'Пропиленгликоль технический и пищевой',
          desc: `Имеет широкое применение в производстве средств личной гигиены, в пищевой промышленности,
          в медицине и косметологии, производстве смесей для электронных сигарет, в химической промышленности и др.`,
          manufacturer: "Китай",
          packing: "Канистра 20кг",
          price: 'от 3500 руб/шт',
        },
        {
          id: 10,
          img: monoetilen,
          name: 'Этиленгликоль (моноэтиленгликоль)',
          desc: `Предназначен для использования в производствах синтетических волокон, растворителей, 
          низкозамерзающих и гидравлических жидкостей, для синтеза ацеталей и фруктона`,
          manufacturer: "АО «Сибур-Нефтехим»",
          packing: "Бочки 220кг (новые)",
          price: 'от 82 руб/кг (с НДС)',
        },
    
      ]

    return (
        <div style={{marginTop: "150px"}}>
            <Row xs={1} md={4} className='d-flex'>
                {Products.map(product => 
                    <ProductItem key={product.id} product={product}/>
                )}
            </Row>
        </div>
    )
}

export default ProductList

import React from 'react'
import './products.css'
import { Col, Container, Row } from 'react-bootstrap'
import ProductList from '../../Components/ProductList'

const Products = () => {

  // const Products = [
  //   {
  //     id: 1,
  //     productImg: alcil,
  //     title: 'Линейная алкилбензолсульфокислота (LABSA) в бочках по 216 кг.',
  //     desc: `Сфера применения:
  //     Поверхностно-активное вещество, используемое в производстве
  //     синтетических моющих средств.
  //     Производство Иран. Высшего качества.
  //     Оптовикам возможны индивидуальные скидки. Отгрузка со склада в
  //     Нижнем Новгороде.`,
  //     priceRoznica: 'Розничная цена: 155000 руб. (с НДС)',
  //     //priceOpt: 'Оптовая цена (от одной тонны): 79 руб/кг (с НДС)'
  //   },
  //   {
  //     id: 2,
  //     productImg: monoetilen,
  //     title: 'Моноэтиленгликоль. МЭГ. 99.9%. Высший сорт. Завод Сибур',
  //     desc: 'Предназначен для использования в производствах синтетических волокон, растворителей, низкозамерзающих и гидравлических жидкостей, для синтеза ацеталей и фруктона',
  //     priceRoznica: 'Розничная цена: 82 руб/кг (с НДС), бочки 220 кг., новые',
  //     //priceOpt: 'Оптовая цена: Бочки 220 кг., новые, 2300 руб.'
  //   },
  //   {
  //     id: 3,
  //     productImg: causticsoda,
  //     title: 'Каустическая сода (гранулированная)',
  //     desc: `Имеет широкое применение: Соду каустическую используют для
  //     бытовых нужд, а также в разных отраслях промышленности.
  //     В быту используется для растворения жира, волос, остатков пищи,
  //     отстирывания пятен мазута с грубой ткани, дезинфекции.
  //     Идеальный материал для очистки выгребных ям, удаления засоров в
  //     любых трубопроводах и канализационных системах.
  //     Продаем оптом и в розницу от одного мешка.
  //     АО "Каустик", г. Волгоград.
  //     Есть доставка.`,
  //     priceRoznica: 'Розничная цена: 2600 руб/мешок',
  //     priceOpt: 'Оптовая цена (от одной тонны): 85 руб/кг (с НДС)'
  //   },
  //   {
  //     id: 4,
  //     productImg: dietanolamid,
  //     title: 'ДИЭТАНОЛАМИД (кокодиэтаноламид). Малайзия.',
  //     desc: `Имеет широкое применение: Производстве средств личной гигиены;
  //     Производство бытовых моющих средств;
  //     Производство промышленных моющих средств;
  //     Производство пластиков в качестве компонента антистатика и ингибитора коррозии`,
  //     priceRoznica: 'Розничная цена: 220 руб/кг (с НДС)',
  //     //priceOpt: 'Оптовая цена (от одной тонны): 85 руб/кг (с НДС)'
  //   },
  //   {
  //     id: 5,
  //     productImg: benzat,
  //     title: 'Бензоат натрия, порошок (Е211). Мешки 25 кг. Китай',
  //     desc: `Часто применимый консервант, обеспечивающий свежесть продуктов. 
  //     Угнетает разввитие дрожжевых клеток, грибов, некоторых видов бактерий. 
  //     В натуральном виде он встречается в яблоках, клюкве, изюме, а также в специях (гвоздике, корице).
  //     Натрий бензойнокислый является водорастворимым веществом. 
  //     Представляет из себя белые кристаллы, которые обладают легким запахом бензальдегида 
  //     или не имеют его вовсе, со сладковатым вкусом.`,
  //     priceRoznica: 'Розничная цена: по запросу',
  //     //priceOpt: 'Оптовая цена (от одной тонны): 85 руб/кг (с НДС)'
  //   },
  //   {
  //     id: 6,
  //     productImg: tripolifosfat,
  //     title: 'Триполифосфат натрия, мешки 50 кг. Россия',
  //     desc: `Основной компонент большинства стиральных порошков.
  //     Это вещество способно разлагать жиры, превращая их в эмульсию. 
  //     Уменьшая жесткость воды, улучшая действие порошка, 
  //     применение триполифосфата натрия делает моющие средства более эффективными. 
  //     Этот реагент является составляющим некоторых косметических продуктов, 
  //     в небольших количествах не причиняя никакого вреда нашему организму.
  //     Кроме того, триполифосфат Na применяется для дезинфекции и дезактивации. 
  //     С его помощью чистят канализацию, растворяя самые сложные засоры.`,
  //     priceRoznica: 'Розничная цена: 120 руб/кг (с НДС)',
  //     //priceOpt: 'Оптовая цена (от одной тонны): 85 руб/кг (с НДС)'
  //   },
  //   {
  //     id: 7,
  //     productImg: propilenglikol,
  //     title: 'Пропиленгликоль, Китай. В наличии технический и пищевой. Бочки 215 кг.',
  //     desc: `По сути, это прозрачная вязкая жидкость, сладкая на вкус и со слабым характерным запахом. 
  //     Пропиленгликоль нетоксичен, быстро смешивается с водой и другими веществами, включая гидрофобные и гидрофильные. 
  //     Обладает низкой температурой замерзания, гигроскопичностью, 
  //     бактерицидным эффектом и умением растворять 1-атомные спирты, кислоты, сложные эфиры и альдегиды.
  //     Имеет широкое применение: Производстве средств личной гигиены;
  //     В пищевой промышленности;
  //     В медицине и косметологии;
  //     В производстве смесей для электронных сигарет;
  //     В антифризах для систем отопления, кондиционирования и вентиляции;
  //     В химической промышленности;
  //     В антиобледенительных жидкостях для самолетов и автомобилей;`,
  //     priceRoznica: `Розничная цена: Технический = 37000 руб/бочка; 
  //     Пищевой = 38000 руб/бочка (с НДС); 
  //     Канистры 20 л = 3500 руб; 
  //     Канистры 10 л = 1900 руб`,
  //     //priceOpt: 'Оптовая цена (от одной тонны): 85 руб/кг (с НДС)'
  //   },

  // ]

  return (
    <div style={{background: "#f0f8ff"}}>
      <Container>
        <Row>
          <Col>
            <ProductList />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Products
